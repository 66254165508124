import React from "react";
import {
  SEO,
  PageLayout,
  OutboundLink,
  Location,
  HoursOfOperation,
  SocialMedia,
  Content,
  ImageCollection,
  Reviews,
} from "@bluefin/components";
import { Header, Grid, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout subfooter={false} className={"home-page"}>
          <Header
            content={fishermanBusiness.name}
            as={"h1"}
            textAlign={"center"}
            className={"businessName"}
          />
          <Grid
            stackable={true}
            className={"grid-locations"}
            verticalAlign={"middle"}
            columns={"equal"}
          >
            <Grid.Column
              width={8}
              textAlign={"right"}
              className={"left-column"}
            >
              <OutboundLink
                to={"https://direct.chownow.com/order/34897/locations/52792"}
              >
                <Image
                  size={"large"}
                  className={"img-left"}
                  centered={false}
                  src={
                    "https://fisherman.gumlet.io/public/aromadiroma/Aroma_Di_Roma_060_1.jpg?width"
                  }
                />
              </OutboundLink>
              <h3>
                <OutboundLink
                  to={"https://direct.chownow.com/order/34897/locations/52792"}
                >
                  Order Online from Belmont Shore
                </OutboundLink>
              </h3>
              <Location
                businessName={"Aroma di Roma Belmont Shore"}
                phone={fishermanBusiness.primaryLocation.phoneNumber}
                address={{
                  street: fishermanBusiness.primaryLocation.street,
                  city: fishermanBusiness.primaryLocation.city,
                  state: fishermanBusiness.primaryLocation.state,
                  zip: fishermanBusiness.primaryLocation.zipCode,
                }}
              />
              <h6>Open Daily</h6>
              <HoursOfOperation
                hours={fishermanBusiness.primaryLocation.hours}
                displayOption={"grouped"}
              />
              <SocialMedia
                socialMediaValues={fishermanBusiness.socialMedia}
                buttonType={"circle"}
                centerHeader={false}
                colors={"secondary"}
                groupButtons={false}
                groupVertically={false}
                header={""}
                inverted={false}
                labelPosition={"free"}
                showLabels={false}
              />
            </Grid.Column>
            <Grid.Column
              width={8}
              textAlign={"left"}
              className={"right-column"}
            >
              <OutboundLink
                to={"https://direct.chownow.com/order/34897/locations/52797"}
              >
                <Image
                  size={"large"}
                  className={"img-right"}
                  centered={false}
                  src={
                    "https://fisherman.gumlet.io/public/aromadiroma/Aroma_Di_Roma_061.jpg"
                  }
                />
              </OutboundLink>
              <h3>
                <OutboundLink
                  to={"https://direct.chownow.com/order/34897/locations/52797"}
                >
                  ORDER ONLINE FROM DOWNTOWN
                </OutboundLink>
              </h3>
              <Location
                businessName={"Aroma di Roma Centro"}
                phone={"5624327662"}
                email={""}
                address={{
                  street: "444 W Ocean Blvd, Suite 110",
                  city: "Long Beach",
                  state: "CA",
                  zip: "90802",
                }}
              />
              <h6>Open Mon -Fri</h6>
              <HoursOfOperation
                hours={[
                  { close: 1500, day: 1, label: "", open: 700 },
                  { close: 1500, day: 2, label: "", open: 700 },
                  { close: 1500, day: 3, label: "", open: 700 },
                  { close: 1500, day: 4, label: "", open: 700 },
                  { close: 1500, day: 5, label: "", open: 700 },
                  { day: 6, label: "" },
                  { day: 7, label: "" },
                ]}
                displayOption={"grouped"}
              />
              <SocialMedia
                socialMediaValues={[
                  {
                    type: "facebook",
                    link: "https://www.facebook.com/AromadiRomaCentro/",
                  },
                  {
                    type: "instagram",
                    link: "https://www.instagram.com/aromadiromacentro/?hl=en",
                  },
                ]}
                buttonType={"circle"}
                centerHeader={false}
                colors={"secondary"}
                groupButtons={false}
                groupVertically={false}
                header={""}
                inverted={false}
                labelPosition={"free"}
                showLabels={false}
              />
            </Grid.Column>
          </Grid>
          <Content
            stackable={true}
            className={"home-about-section"}
            textAlign={"center"}
          >
            <Content.Markup truncate={true} paragraphs={2}>
              {fishermanBusiness.aboutMarkdown}
            </Content.Markup>
          </Content>
          <ImageCollection
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "ImageCollection",
            })}
            as={"gallery"}
            centered={true}
            cutUnevenRows={true}
            smartImageGrouping={false}
            disable={"none"}
            header={""}
            numberOfRowDisplayItems={3}
          />
          <div className={"reviews-container"}>
            <Reviews
              reviews={fishermanBusiness.reviews}
              as={"card"}
              buttonBasic={false}
              buttonSize={"medium"}
              centerHeader={true}
              cardInlinePhoto={"left"}
              header={"Reviews"}
              imageCircular={false}
              imageSize={"small"}
              itemButtonFloat={false}
              itemCenterContent={false}
              itemsDivided={false}
              itemsPerRow={3}
              linkAs={"none"}
              onlyEvenRows={true}
              showImage={true}
              verticalItemAlign={"left"}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        phoneNumber
        street
        city
        state
        zipCode
        hours {
          day
          open
          close
          label
        }
      }
      socialMedia {
        link
        type
      }
      aboutMarkdown
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
